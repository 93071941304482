import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Spinner, Modal, Button, Toast } from "react-bootstrap";
import axiosClient from "../../../utils/axiosClient";
import { useAppDispatch, useAppSelector } from "@/lib/store/hooks";
import { toast } from "react-toastify";
import { setShippingAddress, getTotals } from "@/lib/store/Slices/CartSlice";
import * as appConstants from "./../../../utils/constants";
import { useRouter } from "next/router";
import "./CreateShipping.css";
import "../../ContactUs/ContactForm/ContactForm.css";
import "../../ShipeEgine/CarrierFreightQuote.css";
import { Country, State } from "country-state-city";

function CreateShipping(props) {
  const router = useRouter();
  const cart = useAppSelector((state) => state.cart);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();
  var shippingTextLabel = props.shippingTextLabel || "Shipping";
  const logo = "https://kingsurplus.com/assets/images/logo.webp";
  const [saveChangesClicked, setSaveChangesClicked] = useState(0);
  const [errors, setErrors] = useState({});
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [isClient, setIsClient] = useState(false);

  // country and state
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const divRef = useRef(null);
  const [formValues, setFormValues] = useState({
    nameAlias: "",
    companyName: "",
    email: "",
    contactName: "",
    phoneNumber: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
  });

  useEffect(() => {
    setIsClient(true);
  }, []);
  let userData = {};
  if (isClient) {
    userData = JSON.parse(localStorage.getItem("login")) || {};
  }

  useEffect(() => {
    const allCountries = Country.getAllCountries();
    const formattedCountries = allCountries.map((country) => ({
      name: country.name,
      isoCode: country.isoCode,
    }));
    setCountries(formattedCountries);
    handleCountryChange("US");
  }, []);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    if (props.show != null) {
      setShow(true);
    }
  }, [props.show]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "add_shipping_info",
      ecommerce: {
        currency: "USD",
        value: 0, // Replace with actual value if available
        items: [], // Replace with actual items if available
      },
    });
  });

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const inputRefs = {
    nameAlias: useRef(),
    companyName: useRef(),
    email: useRef(),
    contactName: useRef(),
    phoneNumber: useRef(),
    address1: useRef(),
    city: useRef(),
    state: useRef(),
    zip: useRef(),
  };

  const validateForm = () => {
    const errors = {};
    if (!formValues.nameAlias) errors.nameAlias = "Please enter a nickname.";
    if (!formValues.companyName)
      errors.companyName = "Please enter a company name.";
    if (!validateEmail(formValues.email))
      errors.email = "Please enter a valid email.";
    if (!formValues.contactName)
      errors.contactName = "Please enter a contact name.";
    if (!formValues.phoneNumber)
      errors.phoneNumber = "Please enter a phone number.";
    if (!formValues.address1) errors.address1 = "Please enter an address.";
    if (!formValues.city) errors.city = "Please enter a city.";
    if (!formValues.state) errors.state = "Please select a state.";
    if (!formValues.zip) errors.zip = "Please enter a zip/postal code.";
    return errors;
  };

  useEffect(() => {
    const errors = validateForm();
    setErrors(errors);
    if (saveChangesClicked > 0) {
      const firstErrorField = Object.keys(errors)[0];
      if (firstErrorField) {
        const ref = inputRefs[firstErrorField];
        if (ref && ref.current) {
          ref.current.focus();
        }
      }
    }
  }, [saveChangesClicked]);

  useEffect(() => {
    if (show && inputRefs.nameAlias.current) {
      inputRefs.nameAlias.current.focus();
    }
  }, [show]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setLoading(false);
      setSaveChangesClicked((prev) => prev + 1);
      return;
    } else {
      setErrors({});
      await saveAndUpdateShippingInfo();
    }
  };

  const handleLoginSignup = () => {
    const currentPath = router.asPath;
    router.push(`/login?redirect=${encodeURIComponent(currentPath)}`);
  };
  async function saveAndUpdateShippingInfo() {
    var userID = 0;
    if (userData && userData.user) {
      userID = userData.user.id;
      await saveShipping(userID);
    } else {
      const password = Math.random().toString(36).slice(-8);
      const signupPayload = {
        fullName: formValues.contactName,
        businessName: formValues.companyName,
        email: formValues.email,
        password: password,
        phoneNumber: formValues.phoneNumber,
        relatedIndustry: 0,
        relatedCategory: 0,
      };

      const res = await axiosClient.post("Register", signupPayload);

      if (res && res.isAlreadyRegistor) {
        setToastMessage(
          "This email is already registered. Please log in to the application"
        );
        setShowToast(true);
        setTimeout(() => {
          handleLoginSignup();
        }, 3000);
        userID = res.id;
        setLoading(false);
      } else {
        function getItemDetailsHtml(item) {
          return `<div style="display: flex; flex-direction: column">
                            <div
                              style="
                                display: flex;

                                margin-top: 20px;
                                width: 600px;
                                height: 80px;
                                background: #fff;
                                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                                border-radius: 5px;
                                padding: 12px;
                              "
                            >
                              <div>
                                <div>
                                  <h3>
                                    ${item.description}
                                  </h3>
                                </div>
                                <div
                                  style="
                                    display: flex;
                                    flex-direction: row;
                                    gap: 65px;
                                  "
                                >
                                  <span style="margin-right: 10px" >Quantity : ${item.selectedQty}</span> &nbsp; &nbsp;
                                  <span style="margin-right: 10px >Sale Price : ${item.salePrice} </span> &nbsp; &nbsp;
                                  <span>Refrence number : ${item.assetNumber}</span>
                                </div>
                              </div>
                            </div>
                          </div>`;
        }

        let allItemsHtml = "";
        cart.cartItems.forEach((item) => {
          allItemsHtml += getItemDetailsHtml(item);
        });

        console.log("allItemsHtml", allItemsHtml);
        const welcomeEmail = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
        <html
          xmlns="http://www.w3.org/1999/xhtml"
          xmlns:o="urn:schemas-microsoft-com:office:office"
        >
          <head>
            <meta charset="UTF-8" />
            <meta content="width=device-width, initial-scale=1" name="viewport" />
            <meta name="x-apple-disable-message-reformatting" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta content="telephone=no" name="format-detection" />
            <title>Welcome Template</title>
            <style type="text/css">
              .rollover:hover .rollover-first {
                max-height: 0px !important;
                display: none !important;
              }
              .rollover:hover .rollover-second {
                max-height: none !important;
                display: inline-block !important;
              }
              .rollover div {
                font-size: 0px;
              }
              u ~ div img + div > div {
                display: none;
              }
              #outlook a {
                padding: 0;
              }
              span.MsoHyperlink,
              span.MsoHyperlinkFollowed {
                color: inherit;
                mso-style-priority: 99;
              }
              a.es-button {
                mso-style-priority: 100 !important;
                text-decoration: none !important;
              }
              a[x-apple-data-detectors] {
                color: inherit !important;
                text-decoration: none !important;
                font-size: inherit !important;
                font-family: inherit !important;
                font-weight: inherit !important;
                line-height: inherit !important;
              }
              .es-desk-hidden {
                display: none;
                float: left;
                overflow: hidden;
                width: 0;
                max-height: 0;
                line-height: 0;
                mso-hide: all;
              }
              .es-header-body a:hover {
                color: #666666 !important;
              }
              .es-content-body a:hover {
                color: #5c68e2 !important;
              }
              .es-footer-body a:hover {
                color: #333333 !important;
              }
              .es-infoblock a:hover {
                color: #cccccc !important;
              }
              .es-button-border:hover > a.es-button {
                color: #ffffff !important;
              }
              @media only screen and (max-width: 600px) {
                .es-m-p0r {
                  padding-right: 0px !important;
                }
                *[class="gmail-fix"] {
                  display: none !important;
                }
                p,
                a {
                  line-height: 150% !important;
                }
                h1,
                h1 a {
                  line-height: 120% !important;
                }
                h2,
                h2 a {
                  line-height: 120% !important;
                }
                h3,
                h3 a {
                  line-height: 120% !important;
                }
                h4,
                h4 a {
                  line-height: 120% !important;
                }
                h5,
                h5 a {
                  line-height: 120% !important;
                }
                h6,
                h6 a {
                  line-height: 120% !important;
                }
                h1 {
                  font-size: 36px !important;
                  text-align: left;
                }
                h2 {
                  font-size: 26px !important;
                  text-align: left;
                }
                h3 {
                  font-size: 20px !important;
                  text-align: left;
                }
                h4 {
                  font-size: 24px !important;
                  text-align: left;
                }
                h5 {
                  font-size: 20px !important;
                  text-align: left;
                }
                h6 {
                  font-size: 16px !important;
                  text-align: left;
                }
                .es-header-body h1 a,
                .es-content-body h1 a,
                .es-footer-body h1 a {
                  font-size: 36px !important;
                }
                .es-header-body h2 a,
                .es-content-body h2 a,
                .es-footer-body h2 a {
                  font-size: 26px !important;
                }
                .es-header-body h3 a,
                .es-content-body h3 a,
                .es-footer-body h3 a {
                  font-size: 20px !important;
                }
                .es-header-body h4 a,
                .es-content-body h4 a,
                .es-footer-body h4 a {
                  font-size: 24px !important;
                }
                .es-header-body h5 a,
                .es-content-body h5 a,
                .es-footer-body h5 a {
                  font-size: 20px !important;
                }
                .es-header-body h6 a,
                .es-content-body h6 a,
                .es-footer-body h6 a {
                  font-size: 16px !important;
                }
                .es-menu td a {
                  font-size: 12px !important;
                }
                .es-header-body p,
                .es-header-body a {
                  font-size: 14px !important;
                }
                .es-content-body p,
                .es-content-body a {
                  font-size: 14px !important;
                }
                .es-footer-body p,
                .es-footer-body a {
                  font-size: 14px !important;
                }
                .es-infoblock p,
                .es-infoblock a {
                  font-size: 12px !important;
                }
                .es-m-txt-c,
                .es-m-txt-c h1,
                .es-m-txt-c h2,
                .es-m-txt-c h3,
                .es-m-txt-c h4,
                .es-m-txt-c h5,
                .es-m-txt-c h6 {
                  text-align: center !important;
                }
                .es-m-txt-r,
                .es-m-txt-r h1,
                .es-m-txt-r h2,
                .es-m-txt-r h3,
                .es-m-txt-r h4,
                .es-m-txt-r h5,
                .es-m-txt-r h6 {
                  text-align: right !important;
                }
                .es-m-txt-j,
                .es-m-txt-j h1,
                .es-m-txt-j h2,
                .es-m-txt-j h3,
                .es-m-txt-j h4,
                .es-m-txt-j h5,
                .es-m-txt-j h6 {
                  text-align: justify !important;
                }
                .es-m-txt-l,
                .es-m-txt-l h1,
                .es-m-txt-l h2,
                .es-m-txt-l h3,
                .es-m-txt-l h4,
                .es-m-txt-l h5,
                .es-m-txt-l h6 {
                  text-align: left !important;
                }
                .es-m-txt-r img,
                .es-m-txt-c img,
                .es-m-txt-l img {
                  display: inline !important;
                }
                .es-m-txt-r .rollover:hover .rollover-second,
                .es-m-txt-c .rollover:hover .rollover-second,
                .es-m-txt-l .rollover:hover .rollover-second {
                  display: inline !important;
                }
                .es-m-txt-r .rollover div,
                .es-m-txt-c .rollover div,
                .es-m-txt-l .rollover div {
                  line-height: 0 !important;
                  font-size: 0 !important;
                }
                .es-spacer {
                  display: inline-table;
                }
                a.es-button,
                button.es-button {
                  font-size: 20px !important;
                }
                a.es-button,
                button.es-button {
                  display: inline-block !important;
                }
                .es-button-border {
                  display: inline-block !important;
                }
                .es-m-fw,
                .es-m-fw.es-fw,
                .es-m-fw .es-button {
                  display: block !important;
                }
                .es-m-il,
                .es-m-il .es-button,
                .es-social,
                .es-social td,
                .es-menu {
                  display: inline-block !important;
                }
                .es-adaptive table,
                .es-left,
                .es-right {
                  width: 100% !important;
                }
                .es-content table,
                .es-header table,
                .es-footer table,
                .es-content,
                .es-footer,
                .es-header {
                  width: 100% !important;
                  max-width: 600px !important;
                }
                .adapt-img {
                  width: 100% !important;
                  height: auto !important;
                }
                .es-mobile-hidden,
                .es-hidden {
                  display: none !important;
                }
                .es-desk-hidden {
                  width: auto !important;
                  overflow: visible !important;
                  float: none !important;
                  max-height: inherit !important;
                  line-height: inherit !important;
                }
                tr.es-desk-hidden {
                  display: table-row !important;
                }
                table.es-desk-hidden {
                  display: table !important;
                }
                td.es-desk-menu-hidden {
                  display: table-cell !important;
                }
                .es-menu td {
                  width: 1% !important;
                }
                table.es-table-not-adapt,
                .esd-block-html table {
                  width: auto !important;
                }
                .es-social td {
                  padding-bottom: 10px;
                }
                .h-auto {
                  height: auto !important;
                }
              }
            </style>
          </head>
          <body style="width: 100%; height: 100%; padding: 0; margin: 0">
            <div class="es-wrapper-color" style="background-color: #fafafa">
              <table
                class="es-wrapper"
                width="100%"
                cellspacing="0"
                cellpadding="0"
                style="
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse;
                  border-spacing: 0px;
                  padding: 0;
                  margin: 0;
                  width: 100%;
                  height: 100%;
                  background-repeat: repeat;
                  background-position: center top;
                  background-color: #fafafa;
                "
              >
                <tr>
                  <td valign="top" style="padding: 0; margin: 0">
                    <table
                      cellpadding="0"
                      cellspacing="0"
                      class="es-header"
                      align="center"
                      style="
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        border-collapse: collapse;
                        border-spacing: 0px;
                        width: 100%;
                        table-layout: fixed !important;
                        background-color: transparent;
                        background-repeat: repeat;
                        background-position: center top;
                      "
                    >
                      <tr>
                        <td align="center" style="padding: 0; margin: 0">
                          <table
                            bgcolor="#ffffff"
                            class="es-header-body"
                            align="center"
                            cellpadding="0"
                            cellspacing="0"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              border-collapse: collapse;
                              border-spacing: 0px;
                              background-color: transparent;
                              width: 600px;
                            "
                          >
                            <tr>
                              <td
                                align="left"
                                style="
                                  margin: 0;
                                  padding-top: 10px;
                                  padding-right: 20px;
                                  padding-bottom: 10px;
                                  padding-left: 20px;
                                "
                              >
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                  style="
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                    border-collapse: collapse;
                                    border-spacing: 0px;
                                  "
                                >
                                  <tr>
                                    <td
                                      class="es-m-p0r"
                                      valign="top"
                                      align="center"
                                      style="padding: 0; margin: 0; width: 560px"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tr>
                                          <td
                                            align="center"
                                            style="
                                              padding: 0;
                                              margin: 0;
                                              padding-bottom: 20px;
                                              font-size: 0px;
                                            "
                                          >
                                            <img
                                              src="${logo}"
                                              alt="Logo"
                                              style="
                                                display: block;
                                                font-size: 12px;
                                                border: 0;
                                                outline: none;
                                                text-decoration: none;
                                              "
                                              width="330"
                                              title="Logo"
                                            />
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                    <table
                      cellpadding="0"
                      cellspacing="0"
                      class="es-content"
                      align="center"
                      style="
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        border-collapse: collapse;
                        border-spacing: 0px;
                        width: 100%;
                        table-layout: fixed !important;
                      "
                    >
                      <tr>
                        <td align="center" style="padding: 0; margin: 0">
                          <table
                            bgcolor="#ffffff"
                            class="es-content-body"
                            align="center"
                            cellpadding="0"
                            cellspacing="0"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              border-collapse: collapse;
                              border-spacing: 0px;
                              background-color: #ffffff;
                              width: 600px;
                            "
                          >
                            <tr>
                              <td
                                align="left"
                                style="
                                  padding: 0;
                                  margin: 0;
                                  padding-right: 20px;
                                  padding-left: 20px;
                                  padding-top: 15px;
                                "
                              >
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                  style="
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                    border-collapse: collapse;
                                    border-spacing: 0px;
                                  "
                                >
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="padding: 0; margin: 0; width: 560px"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tr>
                                          <td
                                            align="center"
                                            class="es-m-txt-c"
                                            style="
                                              padding: 0;
                                              margin: 0;
                                              padding-top: 15px;
                                              padding-bottom: 15px;
                                            "
                                          >
                                            <h1
                                              style="
                                                margin: 0;
                                                font-family: arial, 'helvetica neue',
                                                  helvetica, sans-serif;
                                                mso-line-height-rule: exactly;
                                                letter-spacing: 0;
                                                font-size: 46px;
                                                font-style: normal;
                                                font-weight: bold;
                                                line-height: 55px;
                                                color: #333333;
                                              "
                                            >
                                              Thanks for joining us!
                                            </h1>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="center"
                                            style="
                                              padding: 0;
                                              margin: 0;
                                              padding-top: 10px;
                                              padding-bottom: 10px;
                                            "
                                          >
                                            <p
                                              style="
                                                margin: 0;
                                                mso-line-height-rule: exactly;
                                                font-family: arial, 'helvetica neue',
                                                  helvetica, sans-serif;
                                                line-height: 24px;
                                                letter-spacing: 0;
                                                color: #333333;
                                                font-size: 16px;
                                              "
                                            >
                                              Hello,  Below is
                                              your login information.
                                            </p>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td
                                class="esdev-adapt-off"
                                align="left"
                                style="padding: 20px; margin: 0"
                              >
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  class="esdev-mso-table"
                                  style="
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                    border-collapse: collapse;
                                    border-spacing: 0px;
                                    width: 560px;
                                  "
                                >
                                  <tr>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-left"
                                        align="left"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: left;
                                        "
                                      >
                                        <tr class="es-mobile-hidden">
                                          <td
                                            align="left"
                                            style="padding: 0; margin: 0; width: 146px"
                                          >
                                            <table
                                              cellpadding="0"
                                              cellspacing="0"
                                              width="100%"
                                              role="presentation"
                                              style="
                                                mso-table-lspace: 0pt;
                                                mso-table-rspace: 0pt;
                                                border-collapse: collapse;
                                                border-spacing: 0px;
                                              "
                                            >
                                              <tr>
                                                <td
                                                  align="center"
                                                  height="40"
                                                  style="padding: 0; margin: 0"
                                                ></td>
                                              </tr>
                                            </table>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-left"
                                        align="left"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: left;
                                        "
                                      >
                                        <tr>
                                          <td
                                            align="left"
                                            style="padding: 0; margin: 0; width: 121px"
                                          >
                                            <table
                                              cellpadding="0"
                                              cellspacing="0"
                                              width="100%"
                                              bgcolor="#e8eafb"
                                              style="
                                                mso-table-lspace: 0pt;
                                                mso-table-rspace: 0pt;
                                                border-collapse: separate;
                                                border-spacing: 0px;
                                                background-color: #e8eafb;
                                                border-radius: 10px 0 0 10px;
                                                padding: 0 20px;
                                              "
                                              role="presentation"
                                            >
                                              <tr>
                                                <td
                                                  align="left"
                                                  style="
                                                    padding: 0;
                                                    margin: 0;
                                                    padding-top: 10px;
                                                  "
                                                >
                                                  <p
                                                    style="
                                                      margin: 0;
                                                      mso-line-height-rule: exactly;
                                                      font-family: arial,
                                                        'helvetica neue', helvetica,
                                                        sans-serif;
                                                      line-height: 21px;
                                                      letter-spacing: 0;
                                                      color: #333333;
                                                      font-size: 14px;
                                                    "
                                                  >
                                                    Login:
                                                  </p>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  align="left"
                                                  style="
                                                    padding: 0;
                                                    margin: 0;
                                                    padding-bottom: 10px;
                                                  "
                                                >
                                                  <p
                                                    style="
                                                      margin: 0;
                                                      mso-line-height-rule: exactly;
                                                      font-family: arial,
                                                        'helvetica neue', helvetica,
                                                        sans-serif;
                                                      line-height: 21px;
                                                      letter-spacing: 0;
                                                      color: #333333;
                                                      font-size: 14px;
                                                    "
                                                  >
                                                    Password:
                                                  </p>
                                                </td>
                                              </tr>
                                            </table>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-left"
                                        align="left"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: left;
                                        "
                                      >
                                        <tr>
                                          <td
                                            align="left"
                                            style="padding: 0; margin: 0; width: 155px"
                                          >
                                            <table
                                              cellpadding="0"
                                              cellspacing="0"
                                              width="100%"
                                              bgcolor="#e8eafb"
                                              style="
                                                mso-table-lspace: 0pt;
                                                mso-table-rspace: 0pt;
                                                border-collapse: separate;
                                                border-spacing: 0px;
                                                background-color: #e8eafb;
                                                border-radius: 0 10px 10px 0;
                                                width: 250px;
                                              "
                                              role="presentation"
                                            >
                                              <tr>
                                                <td
                                                  align="left"
                                                  style="
                                                    padding: 0;
                                                    margin: 0;
                                                    padding-top: 10px;
                                                    padding-left: 10px;
                                                  "
                                                >
                                                  <p
                                                    style="
                                                      margin: 0;
                                                      mso-line-height-rule: exactly;
                                                      font-family: arial,
                                                        'helvetica neue', helvetica,
                                                        sans-serif;
                                                      line-height: 21px;
                                                      letter-spacing: 0;
                                                      color: #333333;
                                                      font-size: 14px;
                                                    "
                                                  >
                                                    <strong>${formValues.email}</strong>
                                                  </p>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  align="left"
                                                  style="
                                                    padding: 0;
                                                    margin: 0;
                                                    padding-bottom: 10px;
                                                    padding-left: 10px;
                                                  "
                                                >
                                                  <p
                                                    style="
                                                      margin: 0;
                                                      mso-line-height-rule: exactly;
                                                      font-family: arial,
                                                        'helvetica neue', helvetica,
                                                        sans-serif;
                                                      line-height: 21px;
                                                      letter-spacing: 0;
                                                      color: #333333;
                                                      font-size: 14px;
                                                    "
                                                  >
                                                    <strong>${password}</strong>
                                                  </p>
                                                </td>
                                              </tr>
                                            </table>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-right"
                                        align="right"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: right;
                                        "
                                      >
                                        <tr class="es-mobile-hidden">
                                          <td
                                            align="left"
                                            style="padding: 0; margin: 0; width: 138px"
                                          >
                                            <table
                                              cellpadding="0"
                                              cellspacing="0"
                                              width="100%"
                                              role="presentation"
                                              style="
                                                mso-table-lspace: 0pt;
                                                mso-table-rspace: 0pt;
                                                border-collapse: collapse;
                                                border-spacing: 0px;
                                              "
                                            >
                                              <tr>
                                                <td
                                                  align="center"
                                                  height="40"
                                                  style="padding: 0; margin: 0"
                                                ></td>
                                              </tr>
                                            </table>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td
                                align="left"
                                style="
                                  padding: 0;
                                  margin: 0;
                                  padding-right: 20px;
                                  padding-bottom: 10px;
                                  padding-left: 20px;
                                "
                              >
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                  style="
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                    border-collapse: collapse;
                                    border-spacing: 0px;
                                  "
                                >
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="padding: 0; margin: 0; width: 560px"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: separate;
                                          border-spacing: 0px;
                                          border-radius: 5px;
                                        "
                                        role="presentation"
                                      >
                                        <tr>
                                          <td
                                            align="center"
                                            style="
                                              padding: 0;
                                              margin: 0;
                                              padding-top: 10px;
                                              padding-bottom: 10px;
                                            "
                                          >
                                            <span
                                              class="es-button-border"
                                              style="
                                                border-style: solid;
                                                border-color: #2cb543;
                                                background: #0b1145;
                                                border-width: 0px;
                                                display: inline-block;
                                                border-radius: 6px;
                                                width: auto;
                                              "
                                              ><a
                                                href="https://kingsurplus.com"
                                                class="es-button"
                                                target="_blank"
                                                style="
                                                  mso-style-priority: 100 !important;
                                                  text-decoration: none !important;
                                                  mso-line-height-rule: exactly;
                                                  color: #ffffff;
                                                  font-size: 20px;
                                                  padding: 10px 30px 10px 30px;
                                                  display: inline-block;
                                                  background: #0b1145;
                                                  border-radius: 6px;
                                                  font-family: arial, 'helvetica neue',
                                                    helvetica, sans-serif;
                                                  font-weight: normal;
                                                  font-style: normal;
                                                  line-height: 24px !important;
                                                  width: auto;
                                                  text-align: center;
                                                  letter-spacing: 0;
                                                  mso-padding-alt: 0;
                                                  mso-border-alt: 10px solid #0b1145;
                                                  border-left-width: 30px;
                                                  border-right-width: 30px;
                                                "
                                                >SHOP NOW</a
                                              ></span
                                            >
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="center"
                                            style="
                                              padding: 0;
                                              margin: 0;
                                              padding-bottom: 10px;
                                              padding-top: 20px;
                                            "
                                          >
                                            <p
                                              style="
                                                margin: 0;
                                                mso-line-height-rule: exactly;
                                                font-family: arial, 'helvetica neue',
                                                  helvetica, sans-serif;
                                                line-height: 17px !important;
                                                letter-spacing: 0;
                                                color: #333333;
                                                font-size: 14px;
                                              "
                                            >
                                              Got a question? Email us at
                                              <a
                                                href="mailto:support@kingsurplus.com"
                                                style="
                                                  mso-line-height-rule: exactly;
                                                  text-decoration: underline;
                                                  color: #5c68e2;
                                                  font-size: 14px;
                                                "
                                                >support@kingsurplus.com</a
                                              >
                                              or give us a call at&nbsp;<a
                                                target="_blank"
                                                style="
                                                  mso-line-height-rule: exactly;
                                                  text-decoration: underline;
                                                  color: #5c68e2;
                                                  font-size: 14px;
                                                  line-height: 21px;
                                                "
                                                href=""
                                                >830-995-5000</a
                                              >.
                                            </p>
                                            <p
                                              style="
                                                margin: 0;
                                                mso-line-height-rule: exactly;
                                                font-family: arial, 'helvetica neue',
                                                  helvetica, sans-serif;
                                                line-height: 21px;
                                                letter-spacing: 0;
                                                color: #333333;
                                                font-size: 14px;
                                              "
                                            >
                                              <br />Thanks,
                                            </p>
                                            <p
                                              style="
                                                margin: 0;
                                                mso-line-height-rule: exactly;
                                                font-family: arial, 'helvetica neue',
                                                  helvetica, sans-serif;
                                                line-height: 21px;
                                                letter-spacing: 0;
                                                color: #333333;
                                                font-size: 14px;
                                              "
                                            >
                                              King Surplus
                                            </p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style="padding: 0; margin: 0">
                                            <table
                                              cellpadding="0"
                                              cellspacing="0"
                                              width="100%"
                                              class="es-menu"
                                              role="presentation"
                                              style="
                                                mso-table-lspace: 0pt;
                                                mso-table-rspace: 0pt;
                                                border-collapse: collapse;
                                                border-spacing: 0px;
                                              "
                                            >
                                              <tr class="links">
                                                <td
                                                  align="center"
                                                  valign="top"
                                                  width="25.00%"
                                                  style="
                                                    margin: 0;
                                                    border: 0;
                                                    padding-top: 5px;
                                                    padding-bottom: 5px;
                                                    padding-right: 5px;
                                                    padding-left: 5px;
                                                  "
                                                >
                                                  <a
                                                    target="_blank"
                                                    href="https://kingsurplus.com/about-us"
                                                    style="
                                                      mso-line-height-rule: exactly;
                                                      text-decoration: none;
                                                      font-family: arial,
                                                        'helvetica neue', helvetica,
                                                        sans-serif;
                                                      display: block;
                                                      color: #999999;
                                                      font-size: 14px;
                                                    "
                                                    >About us</a
                                                  >
                                                </td>
                                                <td
                                                  align="center"
                                                  valign="top"
                                                  width="25.00%"
                                                  style="
                                                    margin: 0;
                                                    border: 0;
                                                    padding-top: 5px;
                                                    padding-bottom: 5px;
                                                    padding-right: 5px;
                                                    padding-left: 5px;
                                                    border-left: 1px solid #cccccc;
                                                  "
                                                >
                                                  <a
                                                    target="_blank"
                                                    href="https://kingsurplus.com/privacy-policy"
                                                    style="
                                                      mso-line-height-rule: exactly;
                                                      text-decoration: none;
                                                      font-family: arial,
                                                        'helvetica neue', helvetica,
                                                        sans-serif;
                                                      display: block;
                                                      color: #999999;
                                                      font-size: 14px;
                                                    "
                                                    >Privacy Policy</a
                                                  >
                                                </td>
                                                <td
                                                  align="center"
                                                  valign="top"
                                                  width="25.00%"
                                                  style="
                                                    margin: 0;
                                                    border: 0;
                                                    padding-top: 5px;
                                                    padding-bottom: 5px;
                                                    padding-right: 5px;
                                                    padding-left: 5px;
                                                    border-left: 1px solid #cccccc;
                                                  "
                                                >
                                                  <a
                                                    target="_blank"
                                                    href="https://kingsurplus.com/terms-of-service"
                                                    style="
                                                      mso-line-height-rule: exactly;
                                                      text-decoration: none;
                                                      font-family: arial,
                                                        'helvetica neue', helvetica,
                                                        sans-serif;
                                                      display: block;
                                                      color: #999999;
                                                      font-size: 14px;
                                                    "
                                                    >Terms of Services</a
                                                  >
                                                </td>
                                                <td
                                                  align="center"
                                                  valign="top"
                                                  width="25.00%"
                                                  style="
                                                    margin: 0;
                                                    border: 0;
                                                    padding-top: 5px;
                                                    padding-bottom: 5px;
                                                    padding-right: 5px;
                                                    padding-left: 5px;
                                                    border-left: 1px solid #cccccc;
                                                  "
                                                >
                                                  <a
                                                    target="_blank"
                                                    href="https://kingsurplus.com"
                                                    style="
                                                      mso-line-height-rule: exactly;
                                                      text-decoration: none;
                                                      font-family: arial,
                                                        'helvetica neue', helvetica,
                                                        sans-serif;
                                                      display: block;
                                                      color: #999999;
                                                      font-size: 14px;
                                                    "
                                                    >Shop</a
                                                  >
                                                </td>
                                              </tr>
                                            </table>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                    <table
                      cellpadding="0"
                      cellspacing="0"
                      class="es-footer"
                      align="center"
                      style="
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        border-collapse: collapse;
                        border-spacing: 0px;
                        width: 100%;
                        table-layout: fixed !important;
                        background-color: transparent;
                        background-repeat: repeat;
                        background-position: center top;
                      "
                    >
                      <tr>
                        <td align="center" style="padding: 0; margin: 0">
                          <table
                            class="es-footer-body"
                            align="center"
                            cellpadding="0"
                            cellspacing="0"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              border-collapse: collapse;
                              border-spacing: 0px;
                              background-color: transparent;
                              width: 640px;
                            "
                          >
                            <tr>
                              <td
                                align="left"
                                style="
                                  margin: 0;
                                  padding-right: 20px;
                                  padding-left: 20px;
                                  padding-bottom: 20px;
                                  padding-top: 20px;
                                "
                              >
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                  style="
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                    border-collapse: collapse;
                                    border-spacing: 0px;
                                  "
                                >
                                  <tr>
                                    <td
                                      align="left"
                                      style="padding: 0; margin: 0; width: 600px"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tr>
                                          <td
                                            align="center"
                                            style="
                                              padding: 0;
                                              margin: 0;
                                              padding-top: 15px;
                                              padding-bottom: 15px;
                                              font-size: 0;
                                            "
                                          >
                                            <table
                                              cellpadding="0"
                                              cellspacing="0"
                                              class="es-table-not-adapt es-social"
                                              role="presentation"
                                              style="
                                                mso-table-lspace: 0pt;
                                                mso-table-rspace: 0pt;
                                                border-collapse: collapse;
                                                border-spacing: 0px;
                                              "
                                            >
                                              <tr>
                                                <td
                                                  align="center"
                                                  valign="top"
                                                  style="
                                                    padding: 0;
                                                    margin: 0;
                                                    padding-right: 40px;
                                                  "
                                                >
                                                  <a
                                                    target="_blank"
                                                    href="https://www.facebook.com/kingsurpluscompany"
                                                    style="
                                                      mso-line-height-rule: exactly;
                                                      text-decoration: underline;
                                                      color: #333333;
                                                      font-size: 12px;
                                                    "
                                                    ><img
                                                      title="Facebook"
                                                      src="https://szphly.stripocdn.email/content/assets/img/social-icons/logo-black/facebook-logo-black.png"
                                                      alt="Fb"
                                                      width="32"
                                                      style="
                                                        display: block;
                                                        font-size: 14px;
                                                        border: 0;
                                                        outline: none;
                                                        text-decoration: none;
                                                      "
                                                  /></a>
                                                </td>
                                                <td
                                                  align="center"
                                                  valign="top"
                                                  style="
                                                    padding: 0;
                                                    margin: 0;
                                                    padding-right: 40px;
                                                  "
                                                >
                                                  <a
                                                    target="_blank"
                                                    href="https://www.youtube.com/channel/UCF8d0msOA2luUKhzA4Ap-JA"
                                                    style="
                                                      mso-line-height-rule: exactly;
                                                      text-decoration: underline;
                                                      color: #333333;
                                                      font-size: 12px;
                                                    "
                                                    ><img
                                                      title="Youtube"
                                                      src="https://szphly.stripocdn.email/content/assets/img/social-icons/logo-black/youtube-logo-black.png"
                                                      alt="Yt"
                                                      width="32"
                                                      style="
                                                        display: block;
                                                        font-size: 14px;
                                                        border: 0;
                                                        outline: none;
                                                        text-decoration: none;
                                                      "
                                                  /></a>
                                                </td>
                                              </tr>
                                            </table>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="center"
                                            style="
                                              padding: 0;
                                              margin: 0;
                                              padding-bottom: 5px;
                                            "
                                          >
                                            <p
                                              style="
                                                margin: 0;
                                                mso-line-height-rule: exactly;
                                                font-family: arial, 'helvetica neue',
                                                  helvetica, sans-serif;
                                                line-height: 18px;
                                                letter-spacing: 0;
                                                color: #333333;
                                                font-size: 12px;
                                              "
                                            >
                                              © 2025 King Surplus. All rights reserved.
                                              Trademarks and brands belong to their
                                              respective owners.
                                            </p>
                                            <p
                                              style="
                                                margin: 0;
                                                mso-line-height-rule: exactly;
                                                font-family: arial, 'helvetica neue',
                                                  helvetica, sans-serif;
                                                line-height: 18px;
                                                letter-spacing: 0;
                                                color: #333333;
                                                font-size: 12px;
                                              "
                                            >
                                              100 Ave T #4564, Del Rio, TX 78840, United
                                              States
                                            </p>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td
                                align="left"
                                style="
                                  padding: 0;
                                  margin: 0;
                                  padding-right: 20px;
                                  padding-left: 20px;
                                  padding-top: 20px;
                                "
                              >
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  style="
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                    border-collapse: collapse;
                                    border-spacing: 0px;
                                  "
                                >
                                  <tr>
                                    <td
                                      align="left"
                                      style="padding: 0; margin: 0; width: 560px"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tr>
                                          <td
                                            align="center"
                                            style="padding: 0; margin: 0"
                                          >
                                            <p
                                              style="
                                                margin: 0;
                                                mso-line-height-rule: exactly;
                                                font-family: arial, 'helvetica neue',
                                                  helvetica, sans-serif;
                                                line-height: 18px;
                                                letter-spacing: 0;
                                                color: #333333;
                                                font-size: 12px;
                                              "
                                            >
                                              This email was sent by King Surplus If you
                                              don't want to receive this type of email
                                              in the future, please
                                              <a
                                                href="https://kingsurplus.com/Profile"
                                                style="
                                                  mso-line-height-rule: exactly;
                                                  text-decoration: underline;
                                                  color: #3d85c6;
                                                  font-size: 12px;
                                                "
                                                >unsubscribe</a
                                              >
                                            </p>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                    <table
                      cellpadding="0"
                      cellspacing="0"
                      class="es-content"
                      align="center"
                      style="
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        border-collapse: collapse;
                        border-spacing: 0px;
                        width: 100%;
                        table-layout: fixed !important;
                      "
                    >
                      <tr>
                        <td
                          class="es-info-area"
                          align="center"
                          style="padding: 0; margin: 0"
                        >
                          <table
                            class="es-content-body"
                            align="center"
                            cellpadding="0"
                            cellspacing="0"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              border-collapse: collapse;
                              border-spacing: 0px;
                              background-color: transparent;
                              width: 600px;
                            "
                            bgcolor="#FFFFFF"
                          >
                            <tr>
                              <td align="left" style="padding: 20px; margin: 0">
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                  style="
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                    border-collapse: collapse;
                                    border-spacing: 0px;
                                  "
                                >
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="padding: 0; margin: 0; width: 560px"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tr>
                                          <td
                                            align="center"
                                            style="padding: 0; margin: 0; display: none"
                                          ></td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </div>
          </body>
        </html>
        `;
        const UserRegesteredEmail = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html
  xmlns="http://www.w3.org/1999/xhtml"
  xmlns:o="urn:schemas-microsoft-com:office:office"
>
  <head>
    <meta charset="UTF-8" />
    <meta content="width=device-width, initial-scale=1" name="viewport" />
    <meta name="x-apple-disable-message-reformatting" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta content="telephone=no" name="format-detection" />
    <title></title>
  </head>
  <body>
    <div class="es-wrapper-color">
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td class="esd-email-paddings" valign="top">
              <table
                cellpadding="0"
                cellspacing="0"
                class="es-header"
                align="center"
              >
                <tbody>
                  <tr>
                    <td
                      class="esd-stripe esd-synchronizable-module"
                      align="center"
                    >
                      <table
                        bgcolor="#ffffff"
                        class="es-header-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        width="600"
                      >
                        <tbody>
                          <tr>
                            <td
                              class="esd-structure es-p10t es-p10b es-p20r es-p20l"
                              align="left"
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="560"
                                      class="es-m-p0r esd-container-frame"
                                      valign="top"
                                      align="center"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-image es-p20b"
                                              style="font-size: 0px"
                                            >
                                              <a target="_blank"
                                                ><img
                                                  src="${logo}"
                                                  alt="Logo"
                                                  style="
                                                    display: block;
                                                    font-size: 12px;
                                                  "
                                                  width="330"
                                                  title="Logo"
                                              /></a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                cellpadding="0"
                cellspacing="0"
                class="es-content"
                align="center"
              >
                <tbody>
                  <tr>
                    <td class="esd-stripe" align="center">
                      <table
                        bgcolor="#ffffff"
                        class="es-content-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        width="600"
                      >
                        <tbody>
                          <tr>
                            <td
                              class="esd-structure es-p15t es-p20r es-p20l"
                              align="left"
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="560"
                                      class="esd-container-frame"
                                      align="center"
                                      valign="top"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-text es-p15t es-p15b es-m-txt-c"
                                              esd-links-underline="none"
                                            >
                                              <h2>
                                                New User Registered Guest
                                                Checkout (Internal Only)
                                              </h2>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-text es-p10t es-p10b"
                                            >
                                              <p style="font-size: 16px"></p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="esd-structure es-p20t es-p20r es-p20l"
                              align="left"
                            >
                              <table cellpadding="0" cellspacing="0">
                                <tbody>
                                  <tr>
                                    <td
                                      width="560"
                                      class="esd-container-frame"
                                      align="left"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              class="esd-block-text"
                                            >
                                              <p>
                                                <strong style="color: #666666"
                                                  >USER DETAILS</strong
                                                >
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="esd-structure es-p20t es-p20r es-p20l"
                              align="left"
                            >
                              <table
                                width="560"
                                cellpadding="0"
                                cellspacing="0"
                                class="esdev-mso-table"
                              >
                                <tbody>
                                  <tr>
                                    <td class="esdev-mso-td" valign="top">
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        align="left"
                                        class="es-left"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              width="295"
                                              class="esd-container-frame"
                                              align="left"
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                role="presentation"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="left"
                                                      class="esd-block-text"
                                                    >
                                                      <p>
                                                        <strong
                                                          >Full Name :</strong
                                                        >
                                                      </p>
                                                      <p>
                                                        <strong>Email :</strong>
                                                      </p>
                                                      <p>
                                                        <strong
                                                          >Business Name
                                                          :</strong
                                                        >
                                                      </p>
                                                      <p>
                                                        <strong
                                                          >Phone Number
                                                          :</strong
                                                        >
                                                      </p>
                                                      <p>
                                                        <strong>City :</strong>
                                                      </p>
                                                      <p>
                                                        <strong>State :</strong>
                                                      </p>
                                                      <p>
                                                        <strong
                                                          >Zip/Postal Code
                                                          :</strong
                                                        >
                                                      </p>
                                                      <p>
                                                        <strong
                                                          >Address :</strong
                                                        >
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td width="20"></td>
                                    <td class="esdev-mso-td" valign="top">
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-right"
                                        align="right"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              width="245"
                                              class="esd-container-frame"
                                              align="left"
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                role="presentation"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="right"
                                                      class="esd-block-text"
                                                    >
                                                      <p>
                                                        ${formValues.contactName}
                                                      </p>
                                                      <p>${formValues.email}</p>
                                                      <p>
                                                        ${formValues.companyName}
                                                      </p>
                                                      <p>
                                                        ${formValues.phoneNumber}
                                                      </p>
                                                      <p>${formValues.city}</p>
                                                      <p>${formValues.state}</p>
                                                      <p>
                                                        ${formValues.zip}
                                                      </p>
                                                      <p>
                                                        ${formValues.address1} ${formValues.address2}
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      class="esd-structure es-p10b es-p20r es-p20l"
                                      align="left"
                                    >
                                      <!-- ${allItemsHtml} -->

                                      <!-- <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-right"
                                        align="right"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              width="245"
                                              class="esd-container-frame"
                                              align="left"
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                role="presentation"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="right"
                                                      class="esd-block-text"
                                                    >
                                                      <p>
                                                        <strong
                                                          >Item
                                                          Description:</strong
                                                        >
                                                        ${item.description}
                                                      </p>
                                                      <p>
                                                        <strong>Brand:</strong>
                                                        ${item.brandName}
                                                      </p>
                                                      <p>
                                                        <strong
                                                          >Asset Number:</strong
                                                        >
                                                        ${item.assetNumber}
                                                      </p>
                                                      <p>
                                                        <strong
                                                          >Sale Price:</strong
                                                        >
                                                        ${item.salePrice}
                                                      </p>
                                                      <p>
                                                        <strong
                                                          >Selected
                                                          Quantity:</strong
                                                        >
                                                        ${item.selectedQty}
                                                      </p>
                                                      <p>
                                                        <strong
                                                          >Condition:</strong
                                                        >
                                                        ${item.condition}
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table> -->
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>

                          <tr>
                            <td
                              class="esd-structure es-p10b es-p20r es-p20l"
                              align="left"
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="560"
                                      class="esd-container-frame"
                                      align="center"
                                      valign="top"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        style="
                                          border-radius: 5px;
                                          border-collapse: separate;
                                        "
                                      ></table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                cellpadding="0"
                cellspacing="0"
                class="es-content esd-footer-popover"
                align="center"
              >
                <tbody>
                  <tr>
                    <td
                      class="esd-stripe es-info-area esd-synchronizable-module"
                      align="center"
                    >
                      <table
                        class="es-content-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        width="600"
                        style="background-color: transparent"
                        bgcolor="rgba(0, 0, 0, 0)"
                      >
                        <tbody>
                          <tr>
                            <td class="esd-structure es-p20" align="left">
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="560"
                                      class="esd-container-frame"
                                      align="center"
                                      valign="top"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-empty-container"
                                              style="display: none"
                                            ></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          ${allItemsHtml}           
                          <!-- <div>
                            <h2>Items in cart</h2>
                          </div>
                          <div
                            style="
                              display: flex;

                              margin-top: 20px;
                              width: 600px;
                              height: 80px;
                              background: #fff;
                              box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                              border-radius: 5px;
                              padding: 12px;
                            "
                          >
                            <div >
                              <h3>90AMP Fusetron Dual element FRN-R-90 (HRCR-I)
                            </h3>
                             <div style="display: flex; flex-direction: row; gap: 65px;">
                                <span>Quantity :</span> <span>Sale Price : </span> <span>Refrence number : </span>
                             </div>
                            </div>
                            <div>
                          </div> -->
                          <!-- <table cellpadding="0" cellspacing="0" class="es-right" align="right">
                            <tbody>
                              <tr>
                                <td width="245" class="esd-container-frame" align="left">
                                  <table cellpadding="0" cellspacing="0" width="100%" role="presentation">
                                    <tbody>
                                      <tr>
                                        <td align="right" class="esd-block-text">
                                          <p><strong>Item Description:</strong> ${item.description}</p>
                                          <p><strong>Brand:</strong> ${item.brandName}</p>
                                          <p><strong>Asset Number:</strong> ${item.assetNumber}</p>
                                          <p><strong>Sale Price:</strong> ${item.salePrice}</p>
                                          <p><strong>Selected Quantity:</strong> ${item.selectedQty}</p>
                                          <p><strong>Condition:</strong> ${item.condition}</p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table> -->
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </body>
</html>
`;

        if (res && res.email !== "") {
          localStorage.setItem(
            "login",
            JSON.stringify({
              login: true,
              token: res.accessToken,
              user: res,
            })
          );

          userID = res.id;
          dispatch(getTotals());
          fetch(appConstants.SaleLogicEmailUrl, {
            method: "POST",
            body: JSON.stringify({
              body: UserRegesteredEmail,
              subject:
                "New User Registered Using Guest Checkout (Internal Only)",
              to: process.env.SALE_MAIL,
              // to: "dev04@kingsurplus.com",
              replyTo: signupPayload.email,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((response) => {})
            .catch((error) => {
              console.error(error);
              setLoading(false);
            });

          let formData = {
            fullName: signupPayload.fullName,
            businessName: signupPayload.businessName,
            email: signupPayload.email,
            phoneNumber: signupPayload.phoneNumber,
          };
          var bodyHtml = appConstants.getFormDataHtml(formData);
          fetch(appConstants.LogicAppEmailUrl, {
            method: "POST",
            body: JSON.stringify({
              body: welcomeEmail,
              subject: "Welcome To King Surplus",
              to: formData.email,
              replyTo: formData.email,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((response) => {
              // debugger;
            })

            .catch((error) => {
              console.error(error);
              setLoading(false);
            });

          await saveShipping(userID);
        }
      }
    }
  }

  const checkValidation = () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
  };
  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });

    checkValidation();
  };

  const handleCountryChange = (isoCode) => {
    setFormValues({
      ...formValues,
      ["country"]: isoCode,
    });
    checkValidation();
    const allStates = State.getStatesOfCountry(isoCode);
    const formattedStates = allStates.map((state) => ({
      name: state.name,
      isoCode: state.isoCode,
    }));
    setStates(formattedStates);
    ///
  };

  const handleStateChange = (value) => {
    setFormValues({
      ...formValues,
      ["state"]: value,
    });
    checkValidation();
  };

  return (
    <form>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
        autoFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add {shippingTextLabel} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="scroll50 px-2" ref={divRef}>
            <div>
              {toastMessage && <div>{toastMessage}</div>}
              <Row>
                <Col lg={12}>
                  <div className="form-input">
                    <label>{shippingTextLabel} Nickname</label>
                    <input
                      ref={inputRefs.nameAlias}
                      type="text"
                      name="nameAlias"
                      value={formValues.nameAlias}
                      onChange={handleChange}
                    />
                    {errors.nameAlias && (
                      <span style={{ color: "red" }}>{errors.nameAlias}</span>
                    )}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-input">
                    <label>Company Name</label>
                    <input
                      type="text"
                      ref={inputRefs.companyName}
                      name="companyName"
                      value={formValues.companyName}
                      onChange={handleChange}
                    />

                    {errors.companyName && (
                      <span style={{ color: "red" }}>{errors.companyName}</span>
                    )}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-input">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      ref={inputRefs.email}
                      value={formValues.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <span style={{ color: "red" }}>{errors.email}</span>
                    )}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-input">
                    <label>Contact Name</label>
                    <input
                      type="text"
                      ref={inputRefs.contactName}
                      name="contactName"
                      value={formValues.contactName}
                      onChange={handleChange}
                    />
                    {errors.contactName && (
                      <span style={{ color: "red" }}>{errors.contactName}</span>
                    )}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-input">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      name="phoneNumber"
                      ref={inputRefs.phoneNumber}
                      value={formValues.phoneNumber}
                      onChange={handleChange}
                    />
                    {errors.phoneNumber && (
                      <span style={{ color: "red" }}>{errors.phoneNumber}</span>
                    )}
                  </div>
                </Col>

                <Col lg={12}>
                  <div className="form-input">
                    <label>Address 1</label>
                    <input
                      type="text"
                      name="address1"
                      ref={inputRefs.address1}
                      value={formValues.address1}
                      onChange={handleChange}
                    />
                    {errors.address1 && (
                      <span style={{ color: "red" }}>{errors.address1}</span>
                    )}
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="form-input">
                    <label>Address 2</label>
                    <input
                      type="text"
                      name="address2"
                      ref={inputRefs.address2}
                      value={formValues.address2}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-input">
                    <label>City</label>
                    <input
                      type="text"
                      name="city"
                      ref={inputRefs.city}
                      value={formValues.city}
                      onChange={handleChange}
                    />
                    {errors.city && (
                      <span style={{ color: "red" }}>{errors.city}</span>
                    )}
                  </div>
                </Col>

                <Col lg={6}>
                  <div className="form-input">
                    <label>Zip / Postal Code</label>
                    <input
                      type="text"
                      ref={inputRefs.zip}
                      name="zip"
                      value={formValues.zip}
                      onChange={handleChange}
                    />
                    {errors.zip && (
                      <span style={{ color: "red" }}>{errors.zip}</span>
                    )}
                  </div>
                </Col>

                <Col lg={6}>
                  <div className="form-input">
                    <label htmlFor="country">Select Country</label>
                    <select
                      id="country"
                      onChange={(e) => handleCountryChange(e.target.value)}
                      value={formValues.country || ""}
                      ref={inputRefs.country}
                    >
                      <option value="">Select Country</option>
                      {countries.map((country) => (
                        <option key={country.isoCode} value={country.isoCode}>
                          {country.name} ({country.isoCode})
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>

                <Col lg={6}>
                  {states.length > 0 && (
                    <div className="form-input">
                      <label htmlFor="state">Select State</label>
                      <select
                        id="state"
                        value={formValues.state || ""}
                        ref={inputRefs.state}
                        onChange={(event) =>
                          handleStateChange(event.target.value)
                        }
                      >
                        <option value="">Select State</option>
                        {states.map((state) => (
                          <option key={state.isoCode} value={state.isoCode}>
                            {state.name} ({state.isoCode})
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
          <Toast
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={5000}
            autohide
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              zIndex: 1060,
              backgroundColor: "#CC0000",
              color: "white",
              padding: "10px 20px",
              borderRadius: "5px",
            }}
          >
            <Toast.Body>{toastMessage}</Toast.Body>
          </Toast>
        </Modal.Body>
        <Modal.Footer>
          {loading === false && (
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          )}

          {loading ? (
            <Button variant="primary" disabled style={{ position: "relative" }}>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{
                  color: "white",
                  top: "calc(50% - 12px)",
                  left: "calc(50% - 12px)",
                }}
              />
            </Button>
          ) : (
            <Button variant="primary" type="button" onClick={handleFormSubmit}>
              Save Changes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </form>
  );

  async function saveShipping(userID) {
    var body = {
      id: 0,
      nameAlias: formValues.nameAlias,
      companyName: formValues.companyName,
      email: formValues.email,
      contactName: formValues.contactName,
      phoneNumber: formValues.phoneNumber,
      address1: formValues.address1,
      address2: formValues.address2,
      city: formValues.city,
      state: formValues.state,
      zip_PostalCode: formValues.zip,
      country: formValues.country,
      userId: userID,
    };

    try {
      setLoading(true);
      await axiosClient
        .post("saveShippingInfo", body)
        .then((res) => {
          if (res === "") {
            setToastMessage("Please enter a valid address");
            toast.error("Please enter a valid address.");
            setShowToast(true);
            setLoading(false);
            return false;
          }
          if (res != null && props.isFromPB) {
            var shipData = [];
            shipData.push(res);
            dispatch(setShippingAddress(shipData));

            var selectOption = {};
            selectOption.value = res.id;
            selectOption.label = res.nameAlias;
            props.SetValFromPb(selectOption);
          }

          props.refreshData();
          resetForm();
          setLoading(false);
          toast.success("Shipping Addresses added successfully");
          setShow(false);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      setLoading(false);
    }
  }

  function resetForm() {
    setFormValues({
      nameAlias: "",
      companyName: "",
      email: "",
      contactName: "",
      phoneNumber: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "US",
    });
  }
}

export default CreateShipping;
